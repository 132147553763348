import React from "react"
import { Link } from "gatsby"
import styles from "../../styles/components/menu/menu-button.module.scss"
import classNames from "classnames/bind"
import Menu from "./menu"
import MenuItem from "./menuItem"
import HamburgerIcon from "./hamburgerIcon"

let cx = classNames.bind(styles)

const MenuButton = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    //console.log('default menuOpen is ' + this.state.menuOpen)
    //this.toggleScrollLock = this.toggleScrollLock.bind(this)
    if (typeof document !== "undefined") {
      document.body.style.overflow = ""
    }
  }

  toggleScrollLock() {
    // logic is backwards because not accessing updated state?
    if (!this.state.menuOpen) {
      if (typeof document !== "undefined") {
        document.body.style.overflow = "hidden"
      }
    } else {
      if (typeof document !== "undefined") {
        document.body.style.overflow = ""
      }
    }
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen })

    //this.toggleScrollLock()
  }

  handleLinkClick(link) {
    this.setState({ menuOpen: false })
    this.props.updateDataLayer(link)
  }

  render() {
    //console.log('in render after set state : ' + this.state.menuOpen)
    //console.log('menu pos y is ' + this.props.menuPosY)
    let containerClass = cx("container", {
      open: this.state.menuOpen,
    })

    const menu = [
      { id: 0, val: "Tickets", link: "tickets" },
      { id: 1, val: "About", link: "about" },
      ,
      { id: 2, val: "Plan Your Visit", link: "plan-visit" },
      { id: 3, val: "Group and Private Event Information", link: "group-info" },
      { id: 4, val: "Email Sign-Up", link: "email" },
      { id: 5, val: "FAQ", link: "faq" },
      { id: 6, val: "Contact Us", link: "contact" },
      {
        id: 7,
        val: "Privacy Policy",
        link: "https://www.nick.com/legal/wwd7m0/privacy-policy",
      },
      {
        id: 8,
        val: "Legal",
        link: "https://www.nick.com/legal/ussoge/terms-of-use",
      },
      ,
    ]
    const menuItems = menu.map(item => {
      // filter out items that should not appear in U13
      if (
        this.props.under13 &&
        (item.link === "email" ||
          item.link === "contact" ||
          item.link === "press")
      ) {
        return
      }
      return (
        <MenuItem
          key={item.id}
          delay={`${item.id * 0.1}s`}
          onClick={() => {
            this.handleLinkClick(item.link)
          }}
          link={item.link}
          val={item.val}
        >
          {item.val}
        </MenuItem>
      )
    })

    return (
      <div>
        <div className={containerClass}>
          <HamburgerIcon
            open={this.state.menuOpen}
            onClick={() => this.handleMenuClick()}
          />
        </div>
        <Menu open={this.state.menuOpen}>{menuItems}</Menu>
      </div>
    )
  }
}

export default MenuButton
