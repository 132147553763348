import React from "react"
import styles from "../../styles/components/menu/hamburger-icon.module.scss"
import classNames from "classnames/bind"

let cx = classNames.bind(styles)

const HamburgerIcon = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    let containerClass = cx("container", {
      open: this.props.open,
    })
    let lineTopClass = cx("line", "lineTop", {
      open: this.props.open,
    })
    let lineMiddleClass = cx("line", "lineMiddle", {
      open: this.props.open,
    })
    let lineBottomClass = cx("line", "lineBottom", {
      open: this.props.open,
    })

    return (
      <div
        className={containerClass}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick()
              }
        }
      >
        <div className={lineTopClass} />
        <div className={lineMiddleClass} />
        <div className={lineBottomClass} />
      </div>
    )
  }
}

export default HamburgerIcon
