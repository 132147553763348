import React from "react"
import styles from "../../styles/components/menu/menu-item.module.scss"
import { Link } from "gatsby"
import Scroll from "react-scroll"

let ScrollLink = Scroll.Link

const MenuItem = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  handleHover() {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    if (this.props.val === "Privacy Policy" || this.props.val === "Legal") {
      return (
        <a
          className={styles.container}
          href={this.props.link}
          target="_blank"
          onClick={this.props.onClick}
          data-analytics-modname={this.props.link}
        >
          <div className={styles.menuItem}>{this.props.children}</div>
        </a>
      )
    } else {
      return (
        <a
          className={styles.container}
          href={`#` + this.props.link}
          onClick={this.props.onClick}
          data-analytics-modname={this.props.link}
        >
          <div className={styles.menuItem}>{this.props.children}</div>
        </a>
      )
    }
  }
}

export default MenuItem
