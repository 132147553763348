import React from "react"
import styles from "../../styles/components/menu/menu.module.scss"
import classNames from "classnames/bind"
import MenuItem from "./menuItem"
import slimeBorder from "../../images/slime-top-border.png"
import backgroundImage from "../../images/menu-background.png"
import backgroundImageMobile from "../../images/menu-slime-mobile.png"

let cx = classNames.bind(styles)

const Menu = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
      backgroundImage: backgroundImage,
    }
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      // set specific menu backdrop on mobile
      if (window.innerWidth <= 768) {
        this.setState({ backgroundImage: backgroundImageMobile })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  render() {
    let containerClass = cx("container", {
      menuOpen: this.state.open,
    })
    let slimeBorderClass = cx("slimeBorder", { menuOpen: this.state.open })
    return (
      <>
        <div className={containerClass}>
          <div className={slimeBorderClass}>
            <img src={this.state.backgroundImage} alt="" />
          </div>
          {this.state.open && (
            <div className={styles.flexContainer}>
              <div className={styles.menuList}>{this.props.children}</div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default Menu
