import React from "react"
import styles from "../styles/components/bumper.module.scss"

import buttonSlime from "../images/button-slime.png"
import background from "../images/age-gate-background.jpg"

const Bumper = class extends React.Component {
  componentDidMount() {
    let link = this.props.link
    setTimeout(function() {
      window.location.href = link
    }, 5000)
  }

  handleClick() {
    window.location.href = this.props.link
  }

  render() {
    return (
      <div
        className={styles.container}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.flexContainer}>
          <div className={styles.header}>Hey Kids!</div>
          <div className={styles.body}>
            You are about to leave this site to go to a Nickelodeon Experience
            partner site. Always check with your parent before downloading
            anything or giving information online. Never give out your name,
            phone number or address because that stuff is yours and it’s
            private!
          </div>
          <div className={styles.button} onClick={this.handleClick.bind(this)}>
            {/*<div className={styles.buttonSlime}>
              <img src={buttonSlime} alt="slime" />
            </div>*/}
            <span>Continue</span>
          </div>
        </div>
        <div className={styles.timer}>
          This screen will disappear in 5 seconds
        </div>
      </div>
    )
  }
}

export default Bumper
