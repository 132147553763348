import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "../styles/components/header.module.scss"
import MenuButton from "./menu/menuButton"
import Bumper from "../components/bumper"

import instagramIcon from "../images/instagram.svg"

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { bumperOpen: false }
    this.updateDataLayer = this.updateDataLayer.bind(this)
  }
  updateDataLayer(section) {
    this.props.updateDataLayer(section)
  }
  handleClick() {
    if (this.props.under13) {
      this.setState({ bumperOpen: true })
    } else {
      window.open("https://www.instagram.com/nickslimecity/", "__blank")
    }
  }

  render() {
    return (
      <header>
        {this.state.bumperOpen && (
          <Bumper link="https://www.instagram.com/nickslimecity/" />
        )}
        <div className={styles.legalLinks}>
          <span>
            <a
              href="https://www.nick.com/legal/wwd7m0/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </span>
          <span>
            <a
              href="https://www.nick.com/legal/ussoge/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </a>
          </span>
        </div>
        <div
          onClick={this.handleClick.bind(this)}
          style={{ cursor: "pointer" }}
        >
         {!this.props.under13 && (
          <div className={styles.social}>
            <img src={instagramIcon} alt="" />
           
              <div className={styles.cta}>Connect with us</div>
          </div>
        )}
        </div>
        <MenuButton
          updateDataLayer={this.updateDataLayer}
          under13={this.props.under13}
        />
      </header>
    )
  }
}

{
  /*Header.propTypes = {
  siteTitle: PropTypes.string,
  updateDataLayer: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
}*/
}

export default Header
